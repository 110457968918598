import { Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

export const routes: Routes = [
  // FCB Pages
  {
    path: 'request-view',
    loadComponent: () => import('./pages/balance/view/view.page').then((m) => m.ViewPage),
  },
  // 
  {
    path: 'login',
    loadComponent: () => import('./login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'login-otp',
    loadComponent: () => import('./pages/otp/otp.component').then((m) => m.OtpComponent),
  },
  {
    path: 'activate-account',
    loadComponent: () => import('./pages/activate-account/activate-account.page').then((m) => m.ActivateAccountPage),
  },
  // Start
  {
    path: 'balanceview',
    loadComponent: () => import('./pages/balance/view/view.page').then((m) => m.ViewPage),
  },
  {
    path: 'cylinder-view',
    loadComponent: () => import('./pages/balance/view/view.page').then((m) => m.ViewPage),
  },
  {
    path: 'add-cylinder',
    loadComponent: () => import('./pages/EmptyCylinder-Return/add-cylinder/add-cylinder.page').then((m) => m.AddCylinderPage),
  },
  {
    path: 'sustainability',
    loadComponent: () => import('./pages/sustainability/sustainability/sustainability.page').then((m) => m.SustainabilityPage),
  },
  {
    path: 'order-tracking',
    loadComponent: () => import('./pages/order-tracking/order-tracking.page').then( m => m.OrderTrackingPage)
  },
  // {
  //   path: 'emptycylinder',
  //   component: EmptycylinderPage
  // },
  // {
  //   path: 'edit-emptycylinder',
  //   loadChildren: () => import('./pages/EmptyCylinder-Return/edit/edit.module').then( m => m.EditPageModule)
  // },
  // {
  //   path: 'emptycylinderview',
  //   loadChildren: () => import('./pages/EmptyCylinder-Return/emptycylinderview/emptycylinderview.module').then( m => m.EmptycylinderviewPageModule)
  // },
  // End
  // {
  //   path: 'intro',
  //   loadComponent: () => import('./pages/intro/intro').then((m) => m.IntroPage),
  // },
  {
    path: 'intro',
    redirectTo: 'home'
    },
  {
    path: 'events',
    loadComponent: () => import('./pages/events/events').then((m) => m.EventsPage),
  },
  {
    path: 'products',
    loadComponent: () => import('./pages/products/products').then((m) => m.ProductsPage),
  },
  {
    path: 'locations',
    loadComponent: () => import('./pages/locations/locations').then((m) => m.LocationsPage),
  },
  {
    path: 'create-request',
    loadComponent: () => import('./pages/request/create/create.page').then((m) => m.CreatePage),
  },
  {
    path: 'add-cylinder',
    loadComponent: () => import('./pages/EmptyCylinder-Return/add-cylinder/add-cylinder.page').then((m) => m.AddCylinderPage),
  },
  {
    path: 'total-sales',
    loadComponent: () => import('./pages/total-sales/total-sales.page').then((m) => m.TotalSalesPage),
  },
  {
    path: 'payables-chart',
    loadComponent: () => import('./pages/payables-chart/payables-chart.page').then((m) => m.PayablesChartPage),
  },
  {
    path: 'orders-detail',
    loadComponent: () => import('./pages/orders-detail/orders-detail.page').then((m) => m.OrdersDetailPage),
  },
  {
    path: 'payables',
    loadComponent: () => import('./pages/payables/payables/payables.page').then((m) => m.PayablesPage),
  },
  {
    path: 'dispatch-det',
    loadComponent: () => import('./pages/despatch/despatch-det/despatch-det.page').then((m) => m.DespatchDetPage),
  },
  {
    path: 'orders/tabs/orders',
    // component: OrdersTabPage,
    loadComponent: () => import('./pages/orders/order-list/order-list').then((m) => m.OrderListPage),
  },{
    path: 'orders/tabs/despatch',
    // component: OrdersTabPage,
    loadComponent: () => import('./pages/despatch/despatch/despatch.page').then((m) => m.DespatchPage),
    
  },
  {
    path: 'change-password',
    loadComponent: () => import('./pages/change-password/change-password.page').then((m) => m.ChangePasswordPage),
  }, 
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },

  // New Tab Routing ./tabs/finance-tab/finance-tab.page
  {
    path: 'returnable/tabs/:id',
    loadComponent: () => import('./tabs/returnable-tab/returnable-tab.page').then((m) => m.ReturnableTabPage),
    children: [
      {
        path: 'returns',
        loadComponent: () => import('./pages/cylinder-return/cylinder-return/cylinder-return.page').then((m) => m.CylinderReturnPage),
      },
      {
        path: 'passbook',
        loadComponent: () => import('./pages/cylinder-passbook/cylinder-passbook/cylinder-passbook.page').then((m) => m.CylinderPassbookPage),
      }
    ]
  },
  {
    path: 'orders/tabs/:id',
    loadComponent: () => import('./tabs/orders-tab/orders-tab.page').then((m) => m.OrdersTabPage),
    children: [
      {
        path: 'Orders',
        loadComponent: () => import('./pages/orders/order-list/order-list').then((m) => m.OrderListPage),
      },
      {
        path: 'despatch',
        loadComponent: () => import('./pages/despatch/despatch/despatch.page').then((m) => m.DespatchPage),
      },
      {
        path: 'Customer',
        loadComponent: () => import('./pages/orders/customer-po-search/customer-po-search.component').then((m) => m.CustomerPoSearchComponent),
      }
    ]
  },
  {
    path: 'finance/tabs/:id',
    loadComponent: () => import('./tabs/finance-tab/finance-tab.page').then((m) => m.FinanceTabPage),
    children: [
      {
        path: 'payables',
        loadComponent: () => import('./pages/payables/payables/payables.page').then((m) => m.PayablesPage),
      },
      {
        path: 'ledger',
        loadComponent: () => import('./pages/ledger/ledger/ledger.page').then((m) => m.LedgerPage),
      }
    ]
  },

  // direct pages
  
  {
    path: 'floron-contact',
    loadComponent: () => import('./pages/contact/floron-contact/floron-contact.page').then((m) => m.FloronContactPage)  },
  {
    path: 'floron',
    loadComponent: () => import('./pages/product/floron/floron.page').then((m) => m.FloronPage)  
  },
  {
    path: 'floron-details',
    loadComponent: () => import('./pages/product/floron-details/floron-details.page').then((m) => m.FloronDetailsPage)  
  },
  // 
  {
    path: 'supertron-contact',
    loadComponent: () => import('./pages/contact/supertron-contact/supertron-contact.page').then((m) => m.SupertronContactPage)  
  },
  {
    path: 'supertron',
    loadComponent: () => import('./pages/product/supertron/supertron.page').then((m) => m.SupertronPage)  
  },

  {
    path: 'supertron-details',
    loadComponent: () => import('./pages/product/supertron-details/supertron-details.page').then((m) => m.SupertronDetailsPage)  
  },

  
  // 
  {
    path: 'ig-contact',
    loadComponent: () => import('./pages/contact/ig-contact/ig-contact.page').then((m) => m.IgContactPage)  
  },
  {
    path: 'ig',
    loadComponent: () => import('./pages/product/ig/ig.page').then((m) => m.IgPage)  
  },
  {
    path: 'ig-details',
    loadComponent: () => import('./pages/product/ig-details/ig-details.page').then((m) => m.IgDetailsPage)  
  },
  // 
  {
    path: 'packaging-details',
    loadComponent: () => import('./pages/product/packaging-details/packaging-details.page').then((m) => m.PackagingDetailsPage)  
  },
  
  {
    path: 'pfb-bopp-films',
    loadComponent: () => import('./pages/product/pfb-bopp-films/pfb-bopp-films.page').then((m) => m.PfbBoppFilmsPage)  
  },
  {
    path: 'pfb-bopp-films-details',
    loadComponent: () => import('./pages/product/pfb-bopp-films-details/pfb-bopp-films-details.page').then((m) => m.PfbBoppFilmsDetailsPage)  
  },
  {
    path: 'pfb-bopet-films-details',
    loadComponent: () => import('./pages/product/pfb-bopet-films-details/pfb-bopet-films-details.page').then((m) => m.PfbBopetFilmsDetailsPage)  
  },
  {
    path: 'pfb-bopp-films-details/:page',
    loadComponent: () => import('./pages/product/pfb-bopp-films-details/pfb-bopp-films-details.page').then((m) => m.PfbBoppFilmsDetailsPage)  
  },
  {
    path: 'pfb-bopet-films-details/:page',
    loadComponent: () => import('./pages/product/pfb-bopet-films-details/pfb-bopet-films-details.page').then((m) => m.PfbBopetFilmsDetailsPage)  
  },
  
  {
    path: 'pfb-bopet-films',
    loadComponent: () => import('./pages/product/pfb-bopet-films-details/pfb-bopet-films-details.page').then((m) => m.PfbBopetFilmsDetailsPage)  
  },
  {
    path: 'order-detail/:id',
    loadComponent: () => import('./pages/orders-detail/orders-detail.page').then((m) => m.OrdersDetailPage),
  },
  {
    path: 'order-shipment-detail/:id',
    loadComponent: () => import('./pages/orders/order-shipment-detail/order-shipment-detail').then(m => m.OrderShipmentDetPage)
  },
  {
    path: 'certificates',
    loadComponent: () => import('./pages/certificates/certificates/certificates.page').then((m) => m.CertificatesPage),
  },
  {
    path: 'ledger',
    loadComponent: () => import('./pages/ledger/ledger/ledger.page').then((m) => m.LedgerPage),
  },
  {
    path: 'payables',
    loadComponent: () => import('./pages/payables/payables/payables.page').then((m) => m.PayablesPage),
  },
  {
    path: 'payable-det',
    loadComponent: () => import('./pages/payables/payable-det/payable-det.page').then((m) => m.PayableDetPage),
  },
  {
    path: 'bank',
    loadComponent: () => import('./pages/payables/bank/bank.page').then((m) => m.BankPage),
  },
  {
    path: 'aboutus',
    loadComponent: () => import('./pages/aboutus/aboutus.page').then((m) => m.AboutusPage),
  },
  {
    path: 'order-invoice-detail',
    loadComponent: () => import('./pages/orders/order-invoice-detail/order-invoice-detail.page').then((m) => m.OrderInvoiceDetailPage),
  },
  {
    path: 'order-request',
    loadComponent: () => import('./pages/request/requisition/requisition.page').then((m) => m.RequisitionPage),
  },
  {
    path: 'confirmation',
    loadComponent: () => import('./pages/balance/confirmation/confirmation.page').then((m) => m.ConfirmationPage),
  },
  {
    path: 'balance-confirmation',
    loadComponent: () => import('./pages/balance/confirmation/confirmation.page').then((m) => m.ConfirmationPage),
  },
  // TODO:
  {
    path: 'emptycylinder',
    loadComponent: () => import('./pages/EmptyCylinder-Return/emptycylinder/emptycylinder.page').then((m) => m.EmptycylinderPage),
  },
  {
    path: 'edit-emptycylinder',
    loadComponent: () => import('./pages/EmptyCylinder-Return/edit/edit.page').then( m => m.EditPage)
  },
  {
    path: 'emptycylinderview',
    loadComponent: () => import('./pages/EmptyCylinder-Return/emptycylinderview/emptycylinderview.page').then( m => m.EmptycylinderviewPage,)
  },

  {
    path: 'cylinder-passbook-det',
    loadComponent: () => import('./pages/cylinder-passbook/cylinder-passbook-det/cylinder-passbook-det.page').then((m) => m.CylinderPassbookDetPage),
  },
  // 

  

  // ****

  {
    path: 'home',
    loadComponent: () => import('../app/tabs/home-tab/home-tab.page').then((m) => m.HomeTabPage),
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('../app/home-main/home-main.page').then((m) => m.HomeMainPage),
      },
      {
        path: 'dispatch',
        loadComponent: () => import('./pages/despatch/despatch/despatch.page').then((m) => m.DespatchPage),
      },
      {
        path: 'dispatch-det',
        loadComponent: () => import('./pages/despatch/despatch-det/despatch-det.page').then((m) => m.DespatchDetPage),
      },
      {
        path: 'orders-detail',
        loadComponent: () => import('./pages/orders-detail/orders-detail.page').then((m) => m.OrdersDetailPage),
      },
      {
        path: 'request-view',
        loadComponent: () => import('./pages/balance/view/view.page').then((m) => m.ViewPage),
      },
      {
        path: 'requisition',
        loadComponent: () => import('./pages/request/requisition/requisition.page').then((m) => m.RequisitionPage),
      },
      {
        path: 'ledger',
        loadComponent: () => import('./pages/ledger/ledger/ledger.page').then((m) => m.LedgerPage),
      },
      {
        path: 'payables',
        loadComponent: () => import('./pages/payables/payables/payables.page').then((m) => m.PayablesPage),
      },
      {
        path: 'payable-det',
        loadComponent: () => import('./pages/payables/payable-det/payable-det.page').then((m) => m.PayableDetPage),
      },
      {
        path: 'cylinder-passbook',
        loadComponent: () => import('./pages/cylinder-passbook/cylinder-passbook/cylinder-passbook.page').then((m) => m.CylinderPassbookPage),
      },
      {
        path: 'cylinder-return',
        loadComponent: () => import('./pages/cylinder-return/cylinder-return/cylinder-return.page').then((m) => m.CylinderReturnPage),
      },
      {
        path: 'cylinder-passbook-det',
        loadComponent: () => import('./pages/cylinder-passbook/cylinder-passbook-det/cylinder-passbook-det.page').then((m) => m.CylinderPassbookDetPage),
      },
      {
        path: 'cylinder-passbook-item-det',
        loadComponent: () => import('./pages/cylinder-passbook/cylinder-passbook-item-det/cylinder-passbook-item-det.page').then((m) => m.CylinderPassbookItemDetPage),
      },
      {
        path: 'confirmation',
        loadComponent: () => import('./pages/balance/confirmation/confirmation.page').then((m) => m.ConfirmationPage),
      },
      {
        path: 'certificate',
        loadComponent: () => import('./pages/certificates/certificates/certificates.page').then((m) => m.CertificatesPage),
      },
     
    ]
    // loadComponent: () => import('./home-defer/home-defer.page').then((m) => m.HomeDeferPage),
  },
];