import { ApplicationConfig, provideZoneChangeDetection, isDevMode, enableProdMode } from '@angular/core';
import { provideRouter } from '@angular/router';
import { Storage } from '@ionic/storage-angular';

import { routes } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { provideHttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

if (environment.production) {
  enableProdMode();
}

export const appConfig: ApplicationConfig = {
  providers: [Storage,provideHttpClient(),provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes), provideServiceWorker('ngsw-worker.js', {
            enabled: true,
            registrationStrategy: 'registerImmediately' 
          }), provideIonicAngular({mode: 'ios'})]
};
