import { inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanDeactivate, CanLoad, Router } from '@angular/router';
import { AppStore } from './store/app.store';

@Injectable({
  providedIn: 'root'
})
// CanDeactivate<FeedbackComponent>
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad  {

  protected readonly store = inject(AppStore);

  constructor(private router: Router) {}

  canActivate(): boolean {
    return this.checkAuth();
  }

  canActivateChild(): boolean {
    return this.checkAuth();
  }

  // canDeactivate(component: FeedbackComponent): boolean {
  //   if (component.hasUnsavedChanges()) {
  //     return window.confirm('You have unsaved changes. Do you really want to leave?');
  //   }
  //   return true;
  // }

  canLoad(): boolean {
    return this.checkAuth();
  }

  private checkAuth(): boolean {
    if (this.store.$menuCollection()?.length) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

}