<ion-app>
  <ion-menu contentId="main-content">
    <ion-header  class="ion-no-border home-side-nav-wrapper">
      <ion-toolbar>
        <ion-grid class="ion-padding-vertical">
          <ion-row>
            <ion-col size="3">
              <ion-img src="assets/img/profile-picture.png" alt="User Image"></ion-img>
            </ion-col>
            <ion-col size="9">
              <ion-label class="fw-600 text-white">{{UserInfo?.USER_NAME}}</ion-label>
              <ion-menu-toggle class="cursor-pointer" autoHide="false">
                <ion-text (click)="presentFilter('Change Party');"
                  class="change-act d-flex   text-block text-white">{{UserInfo?.PARTY_NAME}} <ion-icon class="account"
                    name="chevron-down-outline"></ion-icon></ion-text>
              </ion-menu-toggle>
              <ion-menu-toggle class="cursor-pointer" autoHide="false">
                <ion-text color="primary" class="ion-margin-end cursor-pointer text-white text-underline" (click)="goToRoute('change-password')"
                  routerLinkActive="active" routerDirection="root">Change Password</ion-text>
                <ion-text class="cursor-pointer text-white text-underline" color="primary" (click)="logout()">Logout</ion-text>
                <!-- <ion-router-link color="primary" class="ion-margin-end" routerLink="../change-password" routerLinkActive="active" routerDirection="root">Change Password</ion-router-link> -->
                <!-- <ion-router-link color="primary" (click)="logout()">Logout</ion-router-link>  -->
              </ion-menu-toggle>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-alert [isOpen]="isAlertOpen" trigger="present-alert" header="Change Password"
        message="Your password has been changed successfully!" [buttons]="alertButtons"
        [buttons]="alertButtons"></ion-alert>
      <div class="d-flex flex-column h-100 justify-content-between">
        <div>
          <!-- <pre>
          {{currentPageToShow|json}}
        </pre> -->
          <ion-list lines="full">
            <ng-container *ngFor="let item of currentPageToShow; let i = index">
              <ng-container *ngIf="item?.MENU_MOBILEURL">
                <ion-menu-toggle class="cursor-pointer" auto-hide="true">

                  <ion-item class="cursor-pointer" (click)="goToRoute(item.MENU_MOBILEURL)">
                    <i [class]="item.MENU_ICON"></i>
                    <b hover-class="color-primary">{{item.Text}}</b>
                  </ion-item>
                </ion-menu-toggle>

              </ng-container>

             


            </ng-container>
            <ion-menu-toggle class="cursor-pointer mt-2" auto-hide="true">
              <div class="d-flex mt-2 m-2">
                
              <!-- <ion-img style="height: 30px; margin-right: 0.5rem;" src="../../assets/img/{{paletteToggle ? 'dark' : 'light'}}.png"></ion-img> -->
              <div class="themeOption ms-1">
                <span>Dark Mode</span>
                <ion-toggle [(ngModel)]="paletteToggle" (ionChange)="toggleChange($event)"
                  justify="space-between"></ion-toggle>
              </div>
              </div>
            </ion-menu-toggle>

          </ion-list>
        </div>
        <div>
          <ion-row>
            <ion-col (click)="goToRoute('/')">
              <img src="assets/img/iconnect-logo.png" style="width:150px" alt="SRF I Connect">
            </ion-col>
          </ion-row>
          <ion-row class="menusocial">
            <ion-col class="ion-text-center" (click)="shareLinkApp('FB')">
              <ion-icon class="logo-footer" name="logo-facebook"></ion-icon>
            </ion-col>
            <ion-col class="ion-text-center" (click)="shareLinkApp('YOU1')">
              <ion-icon class="logo-footer" name="logo-youtube"></ion-icon>
            </ion-col>
            <ion-col class="ion-text-center" (click)="shareLinkApp('YOU2')">
              <ion-icon class="logo-footer" name="logo-youtube"></ion-icon>
            </ion-col>
            <ion-col class="ion-text-center" (click)="shareLinkApp('TWEET')">
              <ion-icon class="logo-footer" name="logo-twitter"></ion-icon>
            </ion-col>
            <ion-col class="ion-text-center" (click)="shareLinkApp('IN')">
              <ion-icon class="logo-footer" name="logo-linkedin"></ion-icon>
            </ion-col>
          </ion-row>
        </div>
      </div>

    </ion-content>

  </ion-menu>
  <div class="ion-page" id="main-content">
    <!-- <ion-header>
      <ion-toolbar>
        <ion-buttons (click)="showMenu()" slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>Menu</ion-title>
      </ion-toolbar>
    </ion-header> -->
    <!-- <ion-content class="ion-padding"> Tap the button in the toolbar to open the menu. </ion-content> -->
  </div>
  <app-new-version-checker />
  <ion-router-outlet></ion-router-outlet>

  <app-footer />
</ion-app>