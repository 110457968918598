import { Component, HostListener, OnInit, CUSTOM_ELEMENTS_SCHEMA, Inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { closeOutline } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, IonicModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FooterPage implements OnInit {
  environmentDetails = environment;
  isOnline: boolean = true;
  promptEvent: any;
  appDownloadSection: boolean = true;
  iosStatus: boolean = Capacitor.getPlatform() === 'ios';
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: any) {
    e.preventDefault();
    this.promptEvent = e;
    //console.log("Listener", this.promptEvent);
  }
  constructor(public platform: Platform,) {
    addIcons({closeOutline})
    this.isOnline = false;
    let isIOSDevice: boolean[] = [this.platform.is('ios'), this.platform.is('ipad'), this.platform.is('iphone')];
    // this.iosStatus = isIOSDevice.some(val => val === true);
    console.log('iosStatus', this.iosStatus);
    
   }

  ngOnInit() {
    this.updateOnlineStatus();
    window.addEventListener('online',  this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));
  }

  private updateOnlineStatus(): void {
    this.isOnline = window.navigator.onLine;
    console.info(`isOnline=[${this.isOnline}]`);
  }

  installPWA() {
    debugger;
    console.log("Install", this.promptEvent);
    this.promptEvent?.prompt();
  }

  shouldInstall(): boolean {
    return !this.isRunningStandalone() && this.promptEvent;
  }

  isRunningStandalone(): boolean {
    return (window.matchMedia('(display-mode: standalone)').matches);
  }

  isPWA() {
    return window.matchMedia('(display-mode: standalone)').matches
  }

}
