import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';
import { NewVersionCheckerService } from '../new-version.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-new-version-checker',
  templateUrl: './new-version-checker.component.html',
  styleUrls: ['./new-version-checker.component.scss'],
  standalone: true,
  imports: [CommonModule],
  encapsulation: ViewEncapsulation.None

})
export class NewVersionCheckerComponent implements OnInit {

  @Input() containerClasses: string;


  constructor(public newVersionCheckerService: NewVersionCheckerService
  ) { 
    console.log('isVersionAvailable', newVersionCheckerService.isNewVersionAvailable);
    
  }

  ngOnInit() { }

  applyUpdate(): void {
    this.newVersionCheckerService.applyUpdate();
}

}
