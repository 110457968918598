import { AfterViewInit, Component, EnvironmentInjector, HostListener, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { IonicModule } from '@ionic/angular';

import { register } from 'swiper/element/bundle';
import { Storage } from '@ionic/storage-angular';
import { Preferences } from '@capacitor/preferences';
import { StorageService } from './providers/storage.service';
import { UserService } from './providers/user.service';
import { Menu } from './interfaces/menu';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filterCircle,filter,searchOutline, homeOutline, home, newspaper, filmOutline,triangle, ellipse, layersOutline, albumsOutline, chevronDownOutline,fileTrayFullOutline, arrowDownCircleOutline, logoFacebook, logoYoutube, logoTwitter, logoLinkedin, chevronForwardOutline, lockClosed, eyeOffOutline, person, receiptOutline, newspaperOutline, calendarOutline, boatOutline, barcodeOutline, readerOutline, cashOutline, qrCodeOutline, fileTrayStackedOutline, starOutline } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { CommonModule } from '@angular/common';
import { LoginService } from './providers/login/login-service';
import { Subject, takeUntil } from 'rxjs';
import { HoverClassDirective } from './atoms/directives/hover-class.directive';
import { Share } from '@capacitor/share';
import { AlertController, Config, MenuController, ModalController, Platform, ToastController } from '@ionic/angular';
import { FilterPage } from './pages/filter/filter.page';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  InfiniteScrollCustomEvent,
  IonBadge,
  IonLabel,
  IonAvatar,
  IonItem,
  IonList,
  IonLoading,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSkeletonText,
  IonAlert, IonButtons } from '@ionic/angular/standalone';
import { FooterPage } from './atoms/footer/footer.component';
import { NewVersionCheckerComponent } from './atoms/pwa-update/new-version-checker/new-version-checker.component';
import { SwUpdate } from '@angular/service-worker';
import { FormsModule } from '@angular/forms';
import { LogUpdateService } from './pwa-services/log-update.service';
import { CheckForUpdateService } from './pwa-services/check-for-update.service';
import { PromptUpdateService } from './pwa-services/prompt-update.service';
import { HandleUnrecoverableStateService } from './pwa-services/handle-unrecoverable-state.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, HoverClassDirective, FooterPage, NewVersionCheckerComponent],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  private logupdate = inject(LogUpdateService)
  private checkupdate = inject(CheckForUpdateService)
  private promptUpdate = inject(PromptUpdateService)
  private handlepwaerror = inject(HandleUnrecoverableStateService)

  private storage = inject(StorageService)
  private userService = inject(UserService)
  @ViewChild(IonContent, { static: false }) content: IonContent;

  bottomMenuList: any[] = [];
  paletteToggle = false;


  darkModeActivated: boolean = false;
  colorList: any = [
    'primary',
    'secondary',
    'tertiary',
    'success',
    'warning',
    'danger',
    'dark',
    'medium',
  ];

  appPages: Menu[] = [
    {
      title: 'Orders',
      url: 'orders/tabs/despatch',
      icon: 'gift-outline'
    },
    {
      title: 'Finance',
      url: 'finance/tabs/payables',
      icon: 'briefcase-outline'
    },
    {
      title: 'Returnables',
      url: 'returnable/tabs/passbook',
      icon: 'git-pull-request-outline'
    }
  ];
  myTransactions = [
    {
      title: 'Order Requisition',
      url: 'home/requisition',
      MENU_MOBILEURL: 'home/requisition',
      Text: 'Order Requisition',
      MENU_MOBICON: 'albums-outline',
      icon: 'albums-outline'
    },
    {
      title: 'Balance Confirmation',
      url: 'home/confirmation',
      MENU_MOBILEURL: 'home/confirmation',
      Text: 'Balance Confirmation',
      MENU_MOBICON: 'wallet-outline',
      icon: 'wallet-outline'
    },
    {
      title: 'Empty Cylinder Return',
      MENU_MOBILEURL: 'emptycylinder',
      Text: 'Empty Cylinder Return',
      MENU_MOBICON: 'server-outline',
      url: 'emptycylinder',
      icon: 'server-outline'
    }
  ];
  cylinder = [
    {
      title: 'Empty Cylinder Return',
      url: 'emptycylinder',
      icon: 'bag-remove-outline'
    }
  ];


  loggedIn = false;
  dark = false;
  UserInfo: any = {};
  //UserProfile: any[] = [];
  MenuList: any = [];
  Version: any = "Browser";
  UserProfile: any[] = [];
  orgId: any;
  getCatCode: any;
  result: any[] = [];
  currentPageToShow = []
  private destroy: any = new Subject();
  isOnline: boolean;
  appDownloadSection: boolean = true;
  iosStatus: boolean = false;
  lastURL: string = ''

  initializeApp() {
    this.platform.ready().then(() => {
      this.showIosInstallBanner();
      this.checkUpdate();

      setInterval(() => {
        this.swUpdate.checkForUpdate();
      } , 21600);
    });
  }

  constructor(private toastController: ToastController, private platform: Platform,private swUpdate: SwUpdate,public activatedRoute: ActivatedRoute, private menu: MenuController,public modalCtrl: ModalController, private loginService: LoginService, public environmentInjector: EnvironmentInjector, private router: Router) { 
    addIcons({ starOutline,fileTrayStackedOutline,qrCodeOutline,cashOutline,readerOutline,barcodeOutline,boatOutline,calendarOutline,newspaperOutline,receiptOutline,person,eyeOffOutline,lockClosed,searchOutline, filter,homeOutline, newspaper,triangle, ellipse, filterCircle, home, layersOutline, albumsOutline, fileTrayFullOutline, chevronDownOutline,arrowDownCircleOutline, logoFacebook, logoYoutube, logoTwitter, logoLinkedin, chevronForwardOutline });

    this.loginService.getTrigger().subscribe(state => {
      this.getUserInfo();
      // this.GetmenuList();
    
    });

    // this.activatedRoute.paramMap.subscribe((params) => {
    //   let currentURL = activatedRoute?.snapshot['_routerState']?.url?.split('/') 
    //   let lastURL = currentURL[currentURL?.length - 1]
    //   this.lastURL = lastURL

    // });

   


    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        console.log('e.url', e.url);
        this.lastURL = e?.url
        if(e?.url === '/home/requisition') {
          this.currentPageToShow = this.myTransactions;
          console.log('currentPageToShow 189', this.currentPageToShow);
          
        } else  if(e?.url === '/home/confirmation') {
          this.currentPageToShow = this.myTransactions;
          console.log('currentPageToShow 189', this.currentPageToShow);
          
        } else  if(e?.url === '/emptycylinder') {
          this.currentPageToShow = this.myTransactions;
          console.log('currentPageToShow 189', this.currentPageToShow);
          
        }  else if(e?.url === '/home') {
          this.GetmenuList();
          this.updateAppPages()
          console.log('currentPageToShow 169', this.currentPageToShow);

        }  else if(e?.url === '/login' || e?.url === '/') {
          this.GetmenuList();
          this.updateAppPages()
          console.log('currentPageToShow 169', this.currentPageToShow);

        } else {
          this.GetmenuList();
          this.updateAppPages()

        }
      }
    });

    this.activatedRoute.paramMap.subscribe((params) => {
      console.log('params', activatedRoute.snapshot['_routerState'].url);
      console.log(activatedRoute.snapshot['_routerState'].url);
      let currentURL = activatedRoute?.snapshot['_routerState']?.url?.split('/') 
      let lastURL = currentURL[currentURL?.length - 1]
      console.log('lastURL 142', lastURL);
      
      this.lastURL = lastURL

      


    });

    this.storage.getTrigger().pipe(
      takeUntil(this.destroy)
    ).subscribe(obj => { 
      if(obj.type === 'SET_SIDE_MENU') {
        // this.getUserInfo();
        this.currentPageToShow = obj.value
        // this.getUserInfo();
        // this.GetmenuList();
      }
    })
  }

  public ngOnDestroy(): void {
    this.destroy.next();
  }

  logout() {
    this.userService.logout().then(() => {
      localStorage.clear();
      return this.router.navigateByUrl('');
    });
    this.storage.clear()
  }

  ngAfterViewInit(): void {
   
  }

  async ngOnInit() {

   
    this.updateOnlineStatus();
    window.addEventListener('online',  this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));

    this.getLoggedIn().then((result: any) => {
      if (result == true) {
        this.getUserInfo();
      }
    })
    this.getUserInfo();
    this.userService.getCatCode().then((getCatCode) => {
      this.getCatCode = getCatCode;
      this.GetmenuList();
    })
  }

  getLoggedIn(): Promise<string> {
    return this.storage.getObject('hasLoggedIn').then((value) => {
      return value;
    });
  }

  ionViewWillEnter() {
    const checkIsDarkMode = localStorage.getItem('darkModeActivated');
    checkIsDarkMode == 'true'
      ? (this.darkModeActivated = true)
      : (this.darkModeActivated = false);
  }

  async presentFilter(pageName) {
    this.menu.close();
    const modal = await this.modalCtrl.create({
      component: FilterPage,
      componentProps: { 
        PageName: pageName,
        ShowPicker: true,
        ShowSite: true, 
        ShowType: false         
       }
    });


    modal.onDidDismiss().then((data: any) => {
      if(data?.data === true) {
        console.log('data', data);
      
        this.menu.open();
        this.redirectTo('/home/tabs/home')
      }
     
      // this.router
      // .navigateByUrl('/home/tabs/home', { replaceUrl: true })
     
    })
    await modal.present();
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([uri])});
  }


  getUserInfo() {
    this.getOrgId();
    this.getPartyName();
    this.getUserName();
  }

  getOrgId() {
    this.userService.getOrgId().then((orgId) => {
      this.orgId = orgId;

    }).then(() => {
      this.userService.getCatCode().then((getCatCode) => {
        this.getCatCode = getCatCode;
       this.GetmenuList();
      })
    });
  }

  getRole(): Promise<string> {
    return this.storage.getObject('Role').then((value) => {
      return value;
    });
  }

  GetmenuList() {
    this.userService.getMenuCollection().then((MenuCollection) => {
      this.MenuList = MenuCollection;
      this.bottomMenuList = this.MenuList?.filter(x => x?.Value === 1 || x?.Value === 2)
      if(this.currentPageToShow?.length === 0) {
        this.updateAppPages()
      }
      
      
    });
  }

  updateAppPages(index = 2) {
    this.currentPageToShow = this.MenuList?.filter(x => x.MENU_PARENTID === index) || []

    console.log('currentPageToShow 327', this.currentPageToShow);


    this.getRole().then((result: any) => {
     if(result === 'Financials' && this.orgId == '278') {
      this.currentPageToShow = this.currentPageToShow?.filter(x => x?.Text !== 'Orders')
     }
  
    })
    
    // this.storageService.setObject('currentPageToShow', JSON.stringify(this.currentPageToShow))
    
  }



  getPartyName() {
    this.userService.getPartyName().then((PartyName) => {
      this.UserInfo.PARTY_NAME = PartyName;
    });
  }

  getUserName() {
   this.userService.getUserName().then((UserName) => {
    
     this.UserInfo.USER_NAME = UserName;
     //this.UserProfile.push(this.UserInfo);
   });

 
 }

  async shareLinkApp(event) {

    await Share.share({
      title: 'Connect App',
      text: 'Use Connect Mobile App',
      url: 'https://dev-pfb.srf.com/',
      dialogTitle: 'Share with buddies',
    });
  }
  goToRoute(event) {
    console.log('event', event);
    
   if(event) {
    this.router.navigateByUrl(event);
   }
  }

  showMenu() {
    let ele = document.querySelector('#main-content');
    ele.classList.add('show-menu')
    let bodyEle = document.querySelector('body');
     bodyEle.classList.add('backdrop-no-scroll')
  }

  getMenuList(): Promise<string> {
    return this.storage.getObject('currentPageToShow').then((value) => {
      return value;
    });
  }

  public promptEvent;

  private updateOnlineStatus(): void {
    this.isOnline = window.navigator.onLine;
    console.info(`isOnline=[${this.isOnline}]`);
  }

  installPWA() {
    debugger;
    console.log("Install", this.promptEvent);
    this.promptEvent.prompt();
  }

  shouldInstall(): boolean {
    return !this.isRunningStandalone() && this.promptEvent;
  }

  isRunningStandalone(): boolean {
    return (window.matchMedia('(display-mode: standalone)').matches);
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    e.preventDefault();
    this.promptEvent = e;
    //console.log("Listener", this.promptEvent);
  }


  async showIosInstallBanner() {
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };  // Detects if device is in standalone mode
    // tslint:disable-next-line:no-string-literal
    const isInStandaloneMode = () => ('standalone' in window.navigator) && window.navigator['standalone'];
  
    // Show the banner once
  
    // Checks if it should display install popup notification
    if (isIos() && !isInStandaloneMode()) {
      const toast = await this.toastController.create({
        cssClass: 'custom-toast',
        position: 'bottom',
        message: `To install the app, tap "Share" icon below and select "Add to Home Screen".`,
      });
      toast.present();
    }
  }
  public alertButtons = [
  
    {
      text: 'SW Update Available',
      role: 'confirm',
      handler: () => {
        this.swUpdate.activateUpdate()
        .then(() => document.location.reload())
        .catch(error => console.error('Failed to apply updates:', error));
      },
    },
  ];

  isAlertOpen: boolean = false
  checkUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event) => {
        if (event.type === "VERSION_READY") {
         this.isAlertOpen = true
    }
      
      });
    }
  }

  // Listen for the toggle check/uncheck to toggle the dark palette
  toggleChange(ev) {
    if(ev.detail.checked) {
      this.storage.setObject('isDark', true)
    } else {
      this.storage.setObject('isDark', false)
    }
    this.toggleDarkPalette(ev.detail.checked);
  }

  // Add or remove the "ion-palette-dark" class on the html element
  toggleDarkPalette(shouldAdd) {
    let eleHTML: any = document.querySelector('html')
    if(shouldAdd) {
      eleHTML.classList.add('ion-palette-dark')
    } else {
      eleHTML.classList.remove('ion-palette-dark')
    }
    
    document.documentElement.classList.toggle('ion-palette-dark', shouldAdd);
  }


}
