<!-- *ngIf="newVersionCheckerService.isNewVersionAvailable" -->
<div *ngIf="newVersionCheckerService.isNewVersionAvailable" class="{{containerClasses}}">
    <div
  (click)="applyUpdate()"
      class="update-banner d-flex align-items-center new-version-checker-container bg-main-color py-2 px-1 robot_newVersionChecker">
      <div class="pr-2">
          <button type="button" class="btn btn-icon-transparent" aria-label="Refresh" >
            <i class="fa-solid fa-rotate-right"></i>
          </button>
      </div>
      <div class="new-version-checker-info text-white">
          <div class="fs-16 fw-400 s1-m mb-1 robot_newVersionChecker_title">
              Update available
          </div>
          <div class="fw-300 fs-14 text-underline s1-r robot_newVersionChecker_information">
              Click here to update Connect >
          </div>
      </div>
  </div>
</div>