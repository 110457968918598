<ion-footer>
  <div>
    <ion-badge [class]="isOnline ? 'onlineStatus hide' : 'onlineStatus'" slot="start" [color]="isOnline ? 'success': 'danger'">{{isOnline ? 'Internet Online': 'Internet Offline'}}</ion-badge>
  </div>
  <div class="appDownload shk_this" *ngIf="appDownloadSection && !iosStatus" [hidden]="!shouldInstall()">
    <ion-icon name="close-outline" (click)="appDownloadSection = false"></ion-icon>
    <div class="appContent">
      <h4>Connect App</h4>
      <p>Install app locally</p>
    </div>
    <ion-button color="primary" shape="round" (click)="installPWA()">Install</ion-button>
  </div>
  <div [class.d-none]="isPWA()" class="appDownloadIOS" *ngIf="iosStatus" (click)="iosStatus = false" [hidden]="!shouldInstall()">
    <ion-icon name="add-circle"></ion-icon>
    <div class="appContent">
      <p>Install this webapp on your device: tab <ion-icon name="share-outline"></ion-icon> and then Add to home screen.
      </p>
    </div>
    <div class="arrow"></div>
  </div>
</ion-footer>